import React, { useEffect, useState } from 'react'
import ItMenu from './ItMenu'
import { GetITDashboardService } from '../../services/ITServices/ItDashboardServices'
import { PiLaptop } from "react-icons/pi";
import { AiOutlineDesktop } from "react-icons/ai";
import { BsMouse } from "react-icons/bs";
import { PiDesktopTower } from "react-icons/pi";
import { TbDeviceMobileCharging } from "react-icons/tb";
import { LiaKeyboardSolid } from "react-icons/lia";
import { PiHardDrives } from "react-icons/pi";
import { MdCable } from "react-icons/md";
import { TfiTicket } from "react-icons/tfi";
import { TbTicketOff } from "react-icons/tb";
import ReactApexChart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';

function ItDashboard() {

  const [data,setdata] = useState({})

  const navigator = useNavigate()

  useEffect(()=>{
    getdata()
  },[])

  async function getdata() {
    const response = await GetITDashboardService()
    let d = response?.data?.data

    let working = [
      d?.graph_data?.laptop?.laptop_working,
      d?.graph_data?.desktop?.desktop_working,
      d?.graph_data?.mobile?.mobile_working,
      d?.graph_data?.keyboard?.keyboard_working,
      d?.graph_data?.mouse?.mouse_working,
      d?.graph_data?.harddisk?.harddisk_working,
      d?.graph_data?.cable?.cable_working,
      d?.graph_data?.cpu?.cpu_working,
    ]

    let trash = [
      d?.graph_data?.laptop?.laptop_trash,
      d?.graph_data?.desktop?.desktop_trash,
      d?.graph_data?.mobile?.mobile_trash,
      d?.graph_data?.keyboard?.keyboard_trash,
      d?.graph_data?.mouse?.mouse_trash,
      d?.graph_data?.harddisk?.harddisk_trash,
      d?.graph_data?.cable?.cable_trash,
      d?.graph_data?.cpu?.cpu_trash,
    ]

    let service = [
      d?.graph_data?.laptop?.laptop_service,
      d?.graph_data?.desktop?.desktop_service,
      d?.graph_data?.mobile?.mobile_service,
      d?.graph_data?.keyboard?.keyboard_service,
      d?.graph_data?.mouse?.mouse_service,
      d?.graph_data?.harddisk?.harddisk_service,
      d?.graph_data?.cable?.cable_service,
      d?.graph_data?.cpu?.cpu_service,
    ]

    let lost = [
      d?.graph_data?.laptop?.laptop_lost,
      d?.graph_data?.desktop?.desktop_lost,
      d?.graph_data?.mobile?.mobile_lost,
      d?.graph_data?.keyboard?.keyboard_lost,
      d?.graph_data?.mouse?.mouse_lost,
      d?.graph_data?.harddisk?.harddisk_lost,
      d?.graph_data?.cable?.cable_lost,
      d?.graph_data?.cpu?.cpu_lost,
    ]

    setchartOptions1({
      ...chartOptions1,series:[
      { 
        name:'Working',
        data:working
      },
      {
        name:'Service',
        data:service
      },
      {
        name:'Trash',
        data:trash
      },
      {
        name:'Lost',
        data:lost
      }
    ]})


    setdata(response?.data?.data)
  }

  const [chartOptions1,setchartOptions1] = useState({
    series: [
      { 
        name:'Working',
        data:[]
      },
      {
        name:'Service',
        data:[]
      },
      {
        name:'Trash',
        data:[]
      },
      {
        name:'Lost',
        data:[]
      },
     
    ],

  chart: {
    toolbar: {
      show: false
    }
  },
    plotOptions: {
      
      bar: {
        horizontal: false,
        dataLabels:{
          enabled: false
        },
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        
      },
    },
    dataLabels: {
      enabled: false
    },
    grid:{
      show: true,
      borderColor: '#fafafa',
    },
  
    stroke: {
      show: true,
      width: 1,
      curve: 'smooth',
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Laptop','Desktop','Mobile','Keyboard','Mouse','HardDisk','Connectors','CPU'],
    },
    yaxis: {
      title: {
        text: 'Inventory Forcast'
      },
      labels:{
        formatter: function (val) {
          return  numDifferentiation(val)
        }
      }
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return numDifferentiation(val)
        }
      }
    },
    colors:['#008ffb','#00e396','#ffb01a','#ff4560','#775dd0']
  })

  function numDifferentiation(value) {
    const val = Math.abs(value)
    if (val >= 10000000) return `${(value / 10000000).toFixed(0)} Cr`
    if (val >= 100000) return `${(value / 100000).toFixed(0)} Lac`
    return value;
  }



  // let rupeeIndian = Intl.NumberFormat("en-IN", {
  //   style: "currency",
  //   currency: "INR",
  // });

  console.log("data",data)

  return (
    <div className=' flex mx-0 box-border  max-h-screen overflow-y-scroll overflow-x-hidden'>
        <div>
            <ItMenu />
        </div>
        <div className='p-4  min-w-[88%] max-w-[88%] '>
          <div className='grid border-l border-r grid-cols-6 items-center justify-evenly border-t border-b border-slate-100'>
            <div className='border-r  border-slate-100 px-2 py-2 w-full' onClick={()=>navigator('/it/inventory/laptop')}>
              <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><PiLaptop size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> Total Laptops</h6>
              <h6 className='text-[14px] mt-1'>{(JSON.stringify(data?.laptop))}</h6>
            </div>

            <div className='border-r  border-slate-100 px-2 py-2 w-full' onClick={()=>navigator('/it/inventory/desktop')}>
              <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineDesktop size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Total Desktop</h6>
              <h6 className='text-[14px] mt-1'>{(JSON.stringify(data?.desktop))}</h6>
            </div>

            <div className='border-r  border-slate-100 px-2 py-2 w-full' onClick={()=>navigator('/it/inventory/cpu')}>
              <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><PiDesktopTower size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Total CPU</h6>
              <h6 className='text-[14px] mt-1'>{(JSON.stringify(data?.cpu))}</h6>
            </div>

            <div className='border-r  border-slate-100 px-2 py-2 w-full' onClick={()=>navigator('/it/inventory/mobile')}>
              <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><TbDeviceMobileCharging size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Total Mobile</h6>
              <h6 className='text-[14px] mt-1'>{(JSON.stringify(data?.mobile))}</h6>
            </div>

            <div className='border-r  border-slate-100 px-2 py-2 w-full' onClick={()=>navigator('/it/inventory/keyboard')}>
              <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><LiaKeyboardSolid size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Total Keyboard</h6>
              <h6 className='text-[14px] mt-1'>{(JSON.stringify(data?.keyboard))}</h6>
            </div>

            <div className='border-r  border-slate-100 px-2 py-2 w-full' onClick={()=>navigator('/it/inventory/hard_disk')}>
              <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><PiHardDrives size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Total HardDisk</h6>
              <h6 className='text-[14px] mt-1'>{(JSON.stringify(data?.harddisk))}</h6>
            </div>
          
          </div>
          <div className='border-l border-slate-100 grid grid-cols-6 items-center justify-evenly'>
            <div className='border-r border-b border-slate-100 px-2 py-2 w-full' onClick={()=>navigator('/it/inventory/cable')}>
              <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdCable size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Total Connectors</h6>
              <h6 className='text-[14px] mt-1'>{(JSON.stringify(data?.connectors))}</h6>
            </div>
            <div className='border-r border-b border-slate-100 px-2 py-2 w-full' onClick={()=>navigator('/it/inventory/mouse')}>
              <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><BsMouse size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Total Connectors</h6>
              <h6 className='text-[14px] mt-1'>{(JSON.stringify(data?.mouse))}</h6>
            </div>
            <div className='border-r border-b border-slate-100 px-2 py-2 w-full' onClick={()=>navigator('/it/ticket_raised')}>
              <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><TfiTicket size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Tickets Pending</h6>
              <h6 className='text-[14px] mt-1'>{(JSON.stringify(data?.support_ticket_pending))}</h6>
            </div>
            <div className='border-r border-b border-slate-100 px-2 py-2 w-full' onClick={()=>navigator('/it/ticket_raised')}>
              <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><TbTicketOff size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Tickets Resolved</h6>
              <h6 className='text-[14px] mt-1'>{(JSON.stringify(data?.support_ticket_resolved))}</h6>
            </div>
          </div>

          <div className='border relative w-[100%] h-[260px]  mt-2 border-slate-100 px-3 py-2 relative mt-2'>
           <h1 className='text-[13px] font-[800]'>Inventory Report</h1>

           <ReactApexChart options={chartOptions1} size={'10'} series={chartOptions1.series} type="bar" width={'100%'}  height={220} />
          </div>
        </div>

       
    </div>
  )
}

export default ItDashboard