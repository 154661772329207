import { deleteRequest, get, post, postfd, publicPost, put } from "../../../helpers/apihelpers";

export const CreateInventoryOptionService=async(data)=>{
    try {
        const result = await publicPost(`api/it/inventory_option/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateInventoryOptionService=async(data,id)=>{
    try {
        const result = await put(`api/it/inventory_option/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetInventoryOptionService=async(page,text,type,stage,status)=>{
    try {
        const result = await get(`api/it/inventory_option/get?page=${page}&text=${text}&type=${type}&stage=${stage}&status=${status}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteInventoryOptionService=async(id)=>{
    try {
        const result = await deleteRequest(`api/it/inventory_option/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadInventoryOptionService=async(data)=>{
    try {
        const result = await postfd(`api/it/inventory_option/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadExcelInventoryOptionService=async(data)=>{
    try {
        const result = await postfd(`api/it/inventory_option/upload_excel`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadExcelInventoryOptionService=async(type)=>{
    try {
        const result = await get(`api/it/inventory_option/download_excel?type=${type}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeviceInventoryHistoryOptionService=async(id,page)=>{
    try {
        const result = await get(`api/it/inventory_option/history/${id}?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}