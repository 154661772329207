import React,{useState,useEffect} from 'react'
import ItMenu from '../ItMenu'
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button';
import { Modal,Select } from 'antd';
import { IconButton } from '@mui/material'
import moment from 'moment';
import { RiBillLine } from 'react-icons/ri';
import { AiOutlineEdit,AiOutlineDelete,AiOutlineFileExcel } from 'react-icons/ai';
import { Tooltip } from '@mui/material';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { DeleteInventoryOptionService, DeviceInventoryHistoryOptionService, DownloadExcelInventoryOptionService, GetInventoryOptionService, UploadExcelInventoryOptionService } from '../../../services/ITServices/InventoryOption/InventoryOptionServices';
import { GetFeatureOptionService } from '../../../services/ITServices/FeatureOption/FeatureOptionServices';
import { HiOutlineDuplicate } from "react-icons/hi";
import fileDownload from "js-file-download";
import axios from 'axios';
import { LuHardDriveDownload } from "react-icons/lu";
import Uploader from '../../../components/Uploader';
import ErrorComponent from '../../../components/errorDesign/ErrorComponent';
import GoBack from '../../../components/back/GoBack';
import { IoChevronForward } from "react-icons/io5";
import Priceconstants from '../../../constants/imageConstants';
import { MdOutlineEdit } from "react-icons/md";

function InventoryOptionDetail() {

  const navigator = useNavigate()
  const {state}  = useLocation()

  const [page,setpage] = useState(1)

  const [datas,setdatas] = useState({})

  useEffect(()=>{
    getdata()
  },[])

  async function getdata() {
    const response = await DeviceInventoryHistoryOptionService(state?._id,page)
    setdatas(response?.data?.data)
  }
  
  return (
    <div className='flex mx-0 box-border  max-h-screen overflow-y-scroll overflow-x-hidden'>
        

        <div>
            <ItMenu />
        </div>
        <div className=' w-[100%]'>
        <div className='flex min-h-screen'>
          

         

          <div className='bg-slate-100 min-h-screen h-[100%] px-4 w-screen'>

          <div className='flex items-center  py-2 text-[10px] font-[700] text-gray-500'>
            <h6 onClick={()=>navigator(-1)} className='w-max mr-2 cursor-pointer'>Devices</h6>
            <IoChevronForward className='mr-2' />
            <h6 className='w-max mr-2 bg-gray-200 p-1 uppercase rounded'>{state?.device_id}</h6>
          </div>

            <GoBack /> 
             


             <div>

             
              <div className='flex'>
                <div className='w-[70px] h-[70px] bg-slate-100 border-[4px] border-white rounded-full'>
                {state?.image !== '' &&  <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${state?.image}`} className='w-[100%] h-[100%] rounded-full' />}
                </div>
                
                <div className='ml-2 mt-2'>
                    <h6 className='capitalize text-[20px] font-[800]'>{state?.name}</h6>
                    <h6 className='text-[11px] -mt-1 font-[700] text-gray-400'>{state?.type}</h6>
                </div>

                
                <div onClick={()=>{navigator('/it/inventory/laptop/edit',{state:state})}} className='absolute right-6 rounded-[10px] flex items-center bg-white px-2 py-[7px] border-[2px] border-gray-200  cursor-pointer'>
                  <h6 className='mr-1 text-[11px] font-[600]' >Edit Device</h6>
                  <MdOutlineEdit size={12} />
                </div>
              </div>

              <div className='w-[79.5vw]'>
              <div className='bg-white p-5 rounded-[10px] mt-5 max-w-[80vw]'>
                 <h6 className='text-[12px] font-[900]'>Device Details</h6>

                 <div className='grid mt-2 grid-cols-5'>

                  <div>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Name</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600]'>{state?.name}</h6>
                  </div>

                  <div>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Category</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600]'>{state?.type}</h6>
                  </div>

                  <div>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Created At</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600]'>{moment(state?.createdAt)?.format('LL')}</h6>
                  </div>

                  <div>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Status</h6>
                    <h6 className='capitalize text-[9px] text-black bg-purple-50 w-max p-1 text-purple-500 rounded-[5px] font-[600]'>{state?.device_status?.name}</h6>
                  </div>

                  <div>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Assigned To</h6>
                    <h6 className='capitalize text-[12px] font-[600] text-blue-400'>{state?.name}</h6>
                  </div>

                  <div className='mt-1'>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Brand</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600]'>{state?.brand?.name}</h6>
                  </div>

                  <div className='mt-1'>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Device Stage</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600]'>{state?.device_stage?.name}</h6>
                  </div>

                  <div className='mt-1'>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Asset Number</h6>
                    <h6 className='capitalize text-[9px] text-black bg-green-50 text-green-500 p-1 rounded-[5px] w-max font-[600]'>{state?.asset_id}</h6>
                  </div>

                  <div className='mt-1'>
                    <h6 className='text-[11px] font-[600] text-gray-400'>MRP</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600]'>{Priceconstants(!['',null,undefined,'null','undefined']?.includes(state?.mrp) ? state?.mrp : 0)}</h6>
                  </div>

                  <div className='mt-1'>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Rent Price</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600]'>{Priceconstants(parseInt(!['',null,undefined,'null','undefined']?.includes(state?.monthly_rent_price) ? state?.monthly_rent_price : 0))}</h6>
                  </div>

                 </div>
              </div>

              <div className='bg-white p-5 rounded-[10px] mt-5 max-w-[80vw]'>
                 <h6 className='text-[12px] font-[900]'>Other Information</h6>

                 <div className='grid mt-2 grid-cols-5'>
                 
                  {['Laptop','Desktop','Mobile']?.includes(state?.type) &&
                  <div>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Processor</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600]'>{state?.processor?.name}</h6>
                  </div>}

                  {['Laptop','Desktop','Mobile','HardDisk']?.includes(state?.type) &&
                  <div>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Storage</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600]'>{state?.storage}</h6>
                  </div> }

                  {['Laptop','Desktop','Mobile',]?.includes(state?.type) &&
                  <div>
                    <h6 className='text-[11px] font-[600] text-gray-400'>RAM</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600]'>{state?.ram}</h6>
                  </div>}

                  <div>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Purchase Type</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600]'>{state?.purchase_type?.name}</h6>
                  </div> 

                  <div>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Device Type</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600]'>{state?.device_type?.name}</h6>
                  </div> 

                 
                 </div>   

                 <div className='mt-2'>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Configuration</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600]'>{state?.configuration}</h6>
                  </div> 
              </div>

              <div className='bg-white p-5 rounded-[10px] mt-5 max-w-[80vw]'>
                 <h6 className='text-[12px] font-[900]'>Device History ({datas?.pagination?.total})</h6>


                 {/* {datas?.data} */}

                 

              </div>
              </div>
             </div>
             


             

          </div>  

       
          </div>
        </div>
    </div>
  )
}

export default InventoryOptionDetail